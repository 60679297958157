import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import HeaderStyles from "./header.module.css"
import MenuIcon from "../../images/menu.svg"
import CloseIcon from "../../images/close.svg"
import LOGO from "../../images/manastir-logo.svg"
import Modal from "../Modal"

const Header = ({ siteTitle }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    return () => {
      document.body.classList.remove("drawer-open")
    }
  }, [])

  const handleDrawerToggle = () => {
    document.body.classList.remove("drawer-open")

    if (!active) {
      document.body.classList.add("drawer-open")
    }

    setActive(!active)
  }

  return (
    <Modal
      name="MODAL"
      title={
        <div className={HeaderStyles.slogan}>
          <span className={HeaderStyles.bold}>manastır</span>
        </div>
      }
    >
      <ul>
        <li>
          <Link
            to={"/culture"}
            partiallyActive={true}
            activeClassName={HeaderStyles.active}
          >
            Culture
          </Link>
        </li>
        <li>
          <Link
            to={"/approach"}
            partiallyActive={true}
            activeClassName={HeaderStyles.active}
          >
            Approach
          </Link>
        </li>
        <li>
          <Link
            to={"/services"}
            partiallyActive={true}
            activeClassName={HeaderStyles.active}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to={"/works"}
            partiallyActive={true}
            activeClassName={HeaderStyles.active}
          >
            Works
          </Link>
        </li>
        <li>
          <Link
            to={"/contact"}
            partiallyActive={true}
            activeClassName={HeaderStyles.active}
          >
            Contact
          </Link>
        </li>
        <li>
          <Link
            to={"/jobs"}
            partiallyActive={true}
            activeClassName={HeaderStyles.active}
          >
            Jobs
          </Link>
        </li>
      </ul>
      <div className="small-title"> </div>
    </Modal>
  )
}

export default Header
