import React from "react"
import { Link } from "gatsby"
import SidebarStyles from "./sidebar.module.css"
import LOGO from "../../images/manastir-logo.svg"
import MOODLEY_LOGO from "../../images/moodley-logo.svg"
function Sidebar() {
  return (
    <>
      <div className={SidebarStyles.sidebar}>
        <div className={SidebarStyles.logo_wrapper}>
          <Link to="/" className={SidebarStyles.link}>
            <img src={LOGO} alt="manastir" className={SidebarStyles.logo} />
          </Link>
        </div>
        <div className={SidebarStyles.sidebar_container}>
          <div className={SidebarStyles.sidebar_wrapper}>
            {" "}
            <div className={SidebarStyles.sidebar_title}>Our</div>
            <ul className={SidebarStyles.navbar}>
              <Link
                to="/culture"
                className={SidebarStyles.link}
                activeClassName={SidebarStyles.active}
              >
                <li className={SidebarStyles.navbar_item}>Culture</li>
              </Link>
              <Link
                to="/approach"
                className={SidebarStyles.link}
                activeClassName={SidebarStyles.active}
              >
                <li className={SidebarStyles.navbar_item}>Approach</li>
              </Link>
              <Link
                to="/services"
                className={SidebarStyles.link}
                activeClassName={SidebarStyles.active}
              >
                <li className={SidebarStyles.navbar_item}>Services</li>
              </Link>
              <Link
                to="/works"
                className={SidebarStyles.link}
                activeClassName={SidebarStyles.active}
              >
                <li className={SidebarStyles.navbar_item}>Works</li>
              </Link>
              <Link
                to="/contact"
                className={SidebarStyles.link}
                activeClassName={SidebarStyles.active}
              >
                <li className={SidebarStyles.navbar_item}>Contact</li>
              </Link>
              <Link
                to="/jobs"
                className={SidebarStyles.link}
                activeClassName={SidebarStyles.active}
              >
                <li className={SidebarStyles.navbar_item}>Jobs</li>
              </Link>
              {/* <Link to="/clients" className={SidebarStyles.link}>
                <li className={SidebarStyles.navbar_item}>Clients</li>
              </Link> */}
            </ul>
          </div>
          {/* <div>
            <a href="https://moodley.at" target="_blank" rel="noopener">
              <img src={MOODLEY_LOGO} alt="Moodley" className={"mb-0"} />
            </a>
          </div> */}
        </div>
      </div>
    </>
  )
}
export default Sidebar
