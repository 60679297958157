import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import ModalStyles from "./modal.module.css"
import MenuIcon from "../../images/menu.svg"
import CloseIcon from "../../images/close.svg"
import LOGO from "../../images/manastir-logo.svg"

const Modal = ({ desc, title, ...props }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    return () => {
      document.body.classList.remove("drawer-open")
    }
  }, [])

  const handleDrawerToggle = () => {
    document.body.classList.remove("drawer-open")

    if (!active) {
      document.body.classList.add("drawer-open")
    }

    setActive(!active)
  }

  return (
    <header className={ModalStyles.header}>
      {desc}
      <div className={`${ModalStyles.row} ${ModalStyles.mobile}`}>
        <div className={`${ModalStyles.hamburger}`}>
          <img src={MenuIcon} alt="Menu" onClick={() => handleDrawerToggle()} />
        </div>
        <div className={`${ModalStyles.logo}`}>
          <Link to={"/"}>
            <img src={LOGO} alt="Manastır" className={ModalStyles.logo} />
          </Link>
        </div>
      </div>
      <div className={`${ModalStyles.drawer} ${active && ModalStyles.active}`}>
        {title}
        <img
          src={CloseIcon}
          alt="Close"
          className={ModalStyles.close}
          onClick={() => handleDrawerToggle()}
        />
        {props.children}
      </div>
    </header>
  )
}

export default Modal
