const SetSubscriptionPopupCookie = (hourToExpire = 24) => {
  let date = new Date()
  let cookieName = "subscriptionPopup"
  let cookieValue = "subscriptionPopup"
  date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000)
  if (document !== undefined) {
    document.cookie =
      cookieName + " = " + cookieValue + "; expires = " + date.toGMTString()
  }
}

export default SetSubscriptionPopupCookie
